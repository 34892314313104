import { FunctionComponent } from "react";
import styles from "./client.module.css";

type ClientType = {
  name: string;
  title: string;
  company: string;
  website: string;
  selected?: boolean;
  onSelect: () => void;
};

const Client: FunctionComponent<ClientType> = ({
  title,
  name,
  company,
  website,
  selected = false,
  onSelect,
}) => {
  const selectedStyle = { borderTop: "2px solid var(--color-deepskyblue)" };
  return (
    <div
      onClick={onSelect}
      className={styles.client}
      style={selected ? selectedStyle : {}}
    >
      <div className={styles.name}>{name}</div>
      <div className={styles.title}>{title}</div>
      <a href={website} target="_blank" className={styles.company}>
        {company}
      </a>
    </div>
  );
};

export default Client;
