import { FunctionComponent, useState } from "react";
import Service from "../components/service";
import Client from "../components/client";
import styles from "./home.module.css";

const clients = [
  {
    name: "Tony Sanders",
    title: "VP of Engineering",
    company: "Catenae Innovation",
    website: "https://www.catenaeinnovation.com/",
  },
  {
    name: "Saeed Agboke",
    title: "CEO",
    company: "Geschwister Fashion",
    website: "https://geschwister.com/",
  },
  {
    name: "Warren Keyes",
    title: "Senior Engineer",
    company: "Lockwood Publishing",
    website: "https://lockwoodpublishing.com/",
  },
];

const testimonials = [
  "Tochito's commitment to innovation has enabled us to deliver exceptional results for our clients. Tochito has consistently provided us with top-notch technical solutions that have improved our efficiency and brought value to our internal and external stakeholders. We're grateful for the partnership with and wholeheartedly recommend their services to other government consulting firms seeking a reliable technology partner.",
  "Tochito has streamlined our e-commerce platform and elevated our online shopping experience. Their technical expertise and dedication to understanding our unique needs have resulted in a significant boost in customer satisfaction and conversion rates. We are proud to be working with them and highly recommend their services to any fashion brand seeking to enhance their digital presence.",
  "Their team of experts truly understands the unique needs of the gaming industry and has consistently delivered engaging content that has significantly improved our user engagement. We couldn't be happier with their services and highly recommend them to any gaming company looking to level up their engineering efforts.",
];

const Home: FunctionComponent = () => {
  const [selectedClient, setSelectedClient] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailStatus, setEmailStatus] = useState<"idle" | "sending">("idle");
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      const data = { name, email, message };
      const submitData = async () => {
        let response: Response;
        setEmailStatus("sending");
        try {
          response = await fetch(
            "https://tochito.co.uk/.netlify/functions/email",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
        } finally {
          setEmailStatus("idle");
        }
        if (response.ok) {
          setName("");
          setEmail("");
          setMessage("");
          alert("Email successfully sent!");
          return;
        }
        alert("Something went wrong. Please try again later.");
      };
      submitData();
    }
  };
  return (
    <div className={styles.home}>
      <b className={styles.tochito}>Tochito</b>
      <section className={styles.hero}>
        <div className={styles.headline}>
          <b
            className={styles.slogan}
          >{`Creating Tomorrows Technology Solutions Today `}</b>
          <div className={styles.tagline}>
            Innovate with Confidence: Expert Developers Dedicated to Your
            Success
          </div>
        </div>
        <img className={styles.imageIcon} alt="" src="/image.svg" />
      </section>
      <div className={styles.value}>
        <b className={styles.coreServices}>Core Services</b>
        <b className={styles.descriptionOfServices}>
          See the services we offer
        </b>
      </div>
      <div className={styles.services}>
        <Service
          image="/icon@2x.png"
          title="Frontend Development"
          description="We provide top-notch frontend development using HTML, Tailwind CSS, React, and TypeScript, delivering interactive and visually appealing websites and apps. Experience excellence today!"
        />
        <Service
          image="/icon1@2x.png"
          title="Backend Development"
          description="We excel in backend development, optimizing your application's performance through databases, back-end logic, APIs, architecture, and server management. Trust our expertise for a seamless online experience!"
        />
        <Service
          image="/icon2@2x.png"
          title="Dev Ops Engineering"
          description="Our proficiency in Dev Ops shines with expertise in cloud technologies like AWS and streamlining software development cycles using CI/CD tools. Elevate your projects with our seamless integration solutions!"
        />
        <Service
          image="/icon3@2x.png"
          title="Artificial Intelligence"
          description="We excel in AI solutions, harnessing the power of OpenAI to transform your projects with cutting-edge intelligence. Elevate your innovations with our AI expertise and experience the future today!"
        />
      </div>
      <div className={styles.section}>
        <img className={styles.imageIcon1} alt="" src="/image1.svg" />
        <div className={styles.offering}>
          <b className={styles.offerToClientsContainer}>
            <p className={styles.boostYourTechnology}>
              Boost your technology workflow
            </p>
            <p className={styles.byChoosingTochito}>by choosing Tochito</p>
          </b>
          <div className={styles.descriptionOfOffer}>
            Custom Software Solutions - Build, Maintain and Upgrade Your
            Business Systems with Tochito
          </div>
        </div>
      </div>
      <div className={styles.value}>
        <b className={styles.coreServices1}>Customer Comments</b>
        <b className={styles.descriptionOfServices}>Why customers love us</b>
      </div>
      <div className={styles.testimonial}>
        <div className={styles.text}>{testimonials[selectedClient]}</div>
        <div className={styles.clients}>
          {clients.map((client, index) => (
            <Client
              key={client.name}
              name={client.name}
              title={client.title}
              company={client.company}
              website={client.website}
              onSelect={() => setSelectedClient(index)}
              selected={index === selectedClient}
            />
          ))}
        </div>
      </div>
      <b className={styles.descriptionOfServices}>Contact Us</b>
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <div className={styles.row}>
          <div className={styles.name}>
            <label htmlFor="name" className={styles.label}>
              Name
            </label>
            <input
              name="name"
              id="name"
              style={{ textTransform: "capitalize" }}
              className={styles.input}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={emailStatus === "sending"}
              required
            />
          </div>
          <div className={styles.email}>
            <label htmlFor="email" className={styles.label}>
              Email
            </label>
            <input
              name="email"
              id="email"
              className={styles.input}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={emailStatus === "sending"}
              required
            />
          </div>
        </div>
        <div className={styles.message}>
          <label htmlFor="message" className={styles.label}>
            Message
          </label>
          <textarea
            id="message"
            name="message"
            rows={10}
            className={styles.input}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={emailStatus === "sending"}
            required
          />
        </div>
        <button
          disabled={emailStatus === "sending"}
          type="submit"
          className={styles.button}
        >
          <b className={styles.contact}>CONTACT</b>
        </button>
      </form>
      <div className={styles.footer}>
        <b className={styles.tochito1}>Tochito</b>
        <div className={styles.copyright2023Tochito}>
          Copyright 2023 Tochito Ltd
        </div>
      </div>
    </div>
  );
};

export default Home;
