import { FunctionComponent } from "react";
import styles from "./service.module.css";

type ServiceType = {
  image?: string;
  title?: string;
  description?: string;
};

const Service: FunctionComponent<ServiceType> = ({
  image,
  title,
  description,
}) => {
  return (
    <div className={styles.frontEnd}>
      <img className={styles.icon} alt="" src={image} />
      <b className={styles.title}>{title}</b>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default Service;
